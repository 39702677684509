<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link
          class="brand-logo"
          :to="{ name: 'login' }"
        >
          <logo />

          <h2 class="brand-text text-primary ml-1">
            {{ appName }}
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('Forgot password?') }}
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('forgotPasswordMessage') }}
        </b-card-text>

        <validation-observer ref="vo">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="onSubmitForm"
          >
            <b-form-group
              :label="$t('Email Address')"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length ? false : null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                  :disabled="isLoading"
                />
                <small class="text-danger">
                  <template v-if="failedRules.required">{{ $t('validationErrorRequiredEmail') }}</template>
                  <template v-else-if="failedRules.email">{{ $t('validationErrorEmail') }}</template>
                  <template v-else>{{ errors[0] }}</template>
                </small>
              </validation-provider>
            </b-form-group>
            <b-button
              variant="primary"
              block
              :disabled="isLoading"
              type="submit"
            >
              {{ $t('Send reset link') }}
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import RESET_PASSWORD from '@/gql/mutation/user/resetPassword.gql'

export default {
  components: {
    Logo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      appName: $themeConfig.app.appName,
      userEmail: '',
      isLoading: false,
      // validation
      required,
      email,
    }
  },
  created() {
    const { userEmail } = this.$route.params
    if (userEmail) {
      this.userEmail = userEmail
    }
  },
  methods: {
    onSubmitForm() {
      this.$refs.vo.validate().then(async isValid => {
        if (!isValid) {
          return
        }

        try {
          this.isLoading = true

          await this.$apollo.mutate({
            mutation: RESET_PASSWORD,
            variables: {
              email: this.userEmail,
            },
          })

          await this.$router.push({ name: 'login' })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Success'),
              text: this.$t('notificationSuccessEmailConfirmationSent'),
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Error'),
              text: Array.isArray(error.graphQLErrors[0].message)
                ? error.graphQLErrors[0].message[0]
                : error.graphQLErrors[0].message,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      })
    },
  },
}
</script>

<style lang="sass">
  @import '@core/scss/vue/pages/page-auth.scss'
</style>
